import { endoso, searchPoliza } from "../../services";

const searchByPoliza = async (
  searchResponse,
  setLoading,
  dispatch,
  history,
  setMessage,
  setDisabled,
  setShowMessage,
  type = 1,
  user,
  setSections21,
  setShowMonthlySalary
) => {
  const createMessage = (text) => {
    setShowMessage(true);
    setMessage({
      text: text,
      severity: "warning",
    });
  };

  if (searchResponse.data?.codigo === 1 || type === 2) {
    const row =
      type === 1 ? searchResponse.data.solicitudes[0] : searchResponse;

    const data = {
      usuario: user,
      nombre: "",
      agente: "",
      pagina: "",
      registros: "",
      oficina: row.oficina,
      ramo: row.ramo,
      poliza: row.poliza,
    };
    const response = await searchPoliza.search(data);
    if (response.data) {
      if (response.data?.codigo === 1) {
        if (row.tipoEndoso === 12) {
          const allresponse = response.data.polizas[0];
          allresponse.configuration = {
            fechainicial: row.fchEmision,
            tipoEndoso: row.tipoEndoso,
            nivel: row.nivel,
            noSolicitud: row.noSolicitud,
            suplemLogico: searchResponse.suplemLogico,
            statusTable: row.descEstatus || null,
            nombreEndoso: row.nombreEndoso,
            descripcionEndoso: row.descTipoEndoso,
          };
          dispatch(allresponse);
          history.push("/endosos");
        } else if (row.tipoEndoso === 16) {
          const data = await endoso.configType16(row.noSolicitud);
          if (data?.data?.codigo === 1) {
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: data.data,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch(allresponse);
            history.push("/endosos");
          } else
            createMessage(
              data?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
        } else if (row.tipoEndoso === 14) {
          const data = await endoso.configType14(row.noSolicitud);
          if (data?.data?.codigo === 1) {
            const dataBody = {
              oficina: row.oficina,
              ramo: row.ramo,
              poliza: row.poliza,
            };
            const dataResponse = await endoso.validaRegla902(dataBody);
            setShowMonthlySalary(dataResponse.data.sumaAseguradaFija);
            const data = await endoso.configType14(row.noSolicitud);
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: data.data,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch(allresponse);
            history.push("/endosos");
          } else
            createMessage(
              data?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
        } else if (row.tipoEndoso === 15) {
          const res = await endoso.nivel902({
            solicitud: row.noSolicitud,
            nivel: row.nivel,
            pagina: 1,
            clave: null,
          });
          if (res?.data?.codigo === 1) {
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: res.data,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch({ ...allresponse, cantidad: res.data.cantidad });

            history.push("/endosos");
          } else {
            createMessage(
              res?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
          }
        } else if (row.tipoEndoso === 20) {
          const res = await endoso.configType20(row.noSolicitud);
          if (res?.data?.codigo === "1") {
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: res.data,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch(allresponse);
            history.push("/endosos");
          } else {
            createMessage(
              res?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
          }
        } else if (row.tipoEndoso === 21) {
          const respSection21 = await endoso.secctions21(row.noSolicitud);
          if (respSection21?.data?.codigo === 1) {
            setSections21(respSection21?.data?.secciones);
          }
          const body = {
            oficina: row.oficina,
            ramo: row.ramo,
            poliza: row.poliza,
          };
          const res = await endoso.configModDescripcion(body);
          if (res?.data?.codigo === "1") {
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: res.data.autos,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.tipoEndoso === 21 ? 1 : row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch(allresponse);
            history.push("/endosos");
          } else {
            createMessage(
              res?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
          }
        } else if (row.tipoEndoso === 18 || row.tipoEndoso === 22 || row.tipoEndoso === 23) {
          // Documentacion: Aca debo aplicar la busqueda de los nuevos R
          const body = {
            oficina: row.oficina,
            ramo: row.ramo,
            poliza: row.poliza,
          };
          const res = await endoso.configModDescripcion(body);
          if (res?.data?.codigo === "1") {
            const allresponse = response.data.polizas[0];
            allresponse.configuration = {
              initialData: res.data.autos,
              fechainicial: row.fchEmision,
              tipoEndoso: row.tipoEndoso,
              nivel: row.tipoEndoso >= 21 && row.tipoEndoso <= 23 ? 1 : row.nivel,
              noSolicitud: row.noSolicitud,
              suplemLogico: searchResponse.suplemLogico,
              statusTable: row.descEstatus || null,
              descripcionEndoso: row.descTipoEndoso,
            };
            dispatch(allresponse);
            history.push("/endosos");
          } else {
            createMessage(
              res?.data?.mensaje || "Ocurrio un error con su solicitud"
            );
          }
        } else {
          /* configuration */
          if (row.nivel === 1) {
            const configBody = {
              solicitud: row.noSolicitud,
              nivel: row.nivel,
              llave: null,
              idPersona: null,
              idDomicilio: null,
            };
            const configResponse = await endoso.configuration(configBody);
            setLoading(false);
            if (type === 1) setDisabled(false);
            /* Si la respuesta es correcta */
            if (
              configResponse.data?.codigo === 1 ||
              configResponse.data?.mensaje ===
              "La persona no puede ser modificada por que no cumple con las condiciones." ||
              configResponse.data?.mensaje ===
              "No hay una persona con el rol BP"
            ) {
              const allresponse = response.data.polizas[0];
              allresponse.configuration = {
                ...configResponse.data,
                fechainicial: row.fchEmision,
                tipoEndoso: row.tipoEndoso,
                nivel: row.nivel,
                noSolicitud: row.noSolicitud,
                suplemLogico: searchResponse.suplemLogico,
                statusTable: row.descEstatus || null,
                descripcionEndoso: row.descTipoEndoso,
              };
              dispatch(allresponse);
              history.push("/endosos");
            } else {
              /* Si la respuesta da error */
              createMessage(
                configResponse.data?.mensaje ||
                "Ocurrio un error con su solicitud"
              );
            }
          } /* niveles */ else {
            const body = {
              solicitud: row.noSolicitud,
              nivel: row.nivel === 3 ? 2 : row.nivel,
              clave: null,
            };
            const levelResponse = await endoso.getLevel(body);
            setLoading(false);
            if (type === 1) setDisabled(false);
            /* Si la respuesta es correcta */
            if (levelResponse.data?.codigo === 1) {
              const allresponse = response.data.polizas[0];
              allresponse.configuration = {
                ...levelResponse.data,
                fechainicial: row.fchEmision,
                tipoEndoso: row.tipoEndoso,
                nivel: row.nivel,
                noSolicitud: row.noSolicitud,
                suplemLogico: searchResponse.suplemLogico,
                statusTable: row.descEstatus || null,
                descripcionEndoso: row.descTipoEndoso,
              };
              dispatch(allresponse);
              history.push("/endosos");
            } else {
              /* Si la respuesta da un error */
              createMessage(
                levelResponse.data?.mensaje ||
                "Ocurrio un error con su solicitud"
              );
            }
          }
        }
      } else {
        setLoading(false);
        createMessage(
          response.data?.mensaje || "Ocurrio un error con su solicitud"
        );
      }
    } else {
      setLoading(false);
      if (type === 1) {
        setDisabled(false);
      }
      createMessage(
        response.data?.mensaje || "Ocurrio un error con su solicitud"
      );
    }
  } else {
    setLoading(false);
    if (type === 1) {
      setDisabled(false);
    }
    createMessage(
      searchResponse.data?.mensaje || "Ocurrio un error con su solicitud"
    );
  }
};

export default searchByPoliza;
