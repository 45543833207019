import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { endoso, searchPoliza } from "../../services";
import DataSection from "./sections/dataSection";
import { Fade, Typography } from "@material-ui/core";
import { AuthContext } from "../../context/context";
import SelectSection from "./sections/selectSection";
import FooterSection from "./sections/footerSection";
import FormsSection from "./sections/formsSection";
import useStyles from "./styles";
import { SnackBar, Spinner } from "../../shared/components";
import { formatDate } from "./hooks";
import { useSubmitData } from "./hooks/submitData";
import { AltaContext, EndosoContext } from "./context";
import { format } from "date-fns";
import FormTable from "./sections/formsSection/formTable";
const Endosos = () => {
  const classes = useStyles();
  /* history */
  const history = useHistory();
  /* new endosos */
  const { setTimer } = useContext(AuthContext);
  const {
    setAlertData,
    setCheckedPersons,
    formDta,
    setFormDta,
    showMessage,
    setShowMessage,
    message,
    setMessage,
    showButtons,
    setShowButtons,
    createMessage,
    setCargaB,
    cargaBData,
    setBajaRiesgo15,
    setAutos,
    setInfoTable,
    infoTable,
    spinner,
    setSpinner,
  } = useContext(EndosoContext);
  const [dataContractor, setDataContractor] = useState();
  const { setInitialData, familiares, titulares, addPersonsSaved, file } =
    useContext(AltaContext);
  /* state */
  const {
    state,
    direction,
    coberturas,
    persons,
    token,
    setPersons,
    setSections21,
    setShowMonthlySalary,
  } = useContext(AuthContext);
  const isBlocked = Boolean(state?.status === "Cancelado" || state?.bloqueo);
  const [types, setType] = useState([]);
  const [rowsTable, setRowsTable] = useState({});
  const [errorsSelect, setErrorsSelect] = useState({});
  const [disableSelects, setDisableSelects] = useState(isBlocked ?? false);
  const [documents, setDocuments] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [oldData, setOldDat] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    oficina: state?.oficina || "",
    poliza: state?.poliza || "",
    ramo: state?.ramo || "",
    fechainicial: state?.configuration?.fechainicial || null,
    tipend: state?.configuration?.tipoEndoso || "",
    descripcionEndoso: state?.configuration?.descripcionEndoso || "",
  });
  const { handleSubmitData: submitData } = useSubmitData();

  const handleDocuments = async () => {
    if (state?.configuration?.noSolicitud === undefined) return;
    const response = await searchPoliza.documents(
      state?.configuration?.noSolicitud,
      state?.configuration?.tipoEndoso >= 21 && state?.configuration?.tipoEndoso <= 23 ? 'P' : "PE",
      state?.oficina || "",
      state?.ramo || "",
      state?.poliza || "",
      state?.configuration?.suplemLogico || ""
    );

    if (response) setDocuments(response.data);
  }

  useEffect(() => {
    handleDocuments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* methods */
  const handleChangeData = (e) => {
    setTimer(0);
    if(e.target.name === "tipend") {
    setData({ ...data, [e.target.name]: e.target.value.idEndoso, descripcionEndoso: e.target.value.descEndoso });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };
  const handleSubmitData = async () => {
    const dataSend16 = {
      familiares: familiares,
      titulares: titulares,
      addPersonsSaved: addPersonsSaved,
      file: file,
    };
    setTimer(0);
    const submitProps = {
      setSpinner: setSpinner,
      data: data,
      oldData: oldData,
      dataSend:
        data.tipend === 16
          ? dataSend16
          : data.tipend === 20
            ? cargaBData
            : dataSend,
      setMessage: setMessage,
      setShowMessage: setShowMessage,
      direction: direction,
      formDta: formDta,
      endoso: endoso,
      setInfoTable: setInfoTable,
      setShowButtons: setShowButtons,
      showButtons: showButtons,
      coberturas: coberturas,
      rowsTable: rowsTable,
      persons: persons,
      setPersons: setPersons,
      setAlertData: setAlertData,
      setCheckedPersons: setCheckedPersons,
      createMessage: createMessage,
      history: history,
    };
    await submitData(submitProps);
  };
  useEffect(() => {
    const getBloquea = async () => {
      setSpinner(true);
      const dateIntial =  format(new Date(data.fechainicial), "dd/MM/yyyy");

      const searchBody = {
        ...data,
        fechainicial: dateIntial,
        usuario: token.cdUsuario,
      };
      const bloqueaResponse = await searchPoliza.bloquea(searchBody);
      if (bloqueaResponse && !bloqueaResponse.data?.error?.[0]?.desError && data.tipend === 14) {
        createMessage({
          text: bloqueaResponse.data.alerta,
          variant: 'success'
        })
      }
      if (bloqueaResponse.data?.error?.[0]?.desError) {
        if (data.tipend === 3) {
          setErrorsSelect({
            ...errorsSelect,
            date: bloqueaResponse.data?.error?.[0].desError,
          });
        } else {
          setErrorsSelect({
            ...errorsSelect,
            select: bloqueaResponse.data?.error?.[0].desError,
          });
        }
      } else {
        setErrorsSelect({});
      }
      if (bloqueaResponse.data?.codigo === 1) {
        setDisableSelects(true);
        if (data.tipend === 12) {
          setFormDta({
            nivel: bloqueaResponse.data.nivel,
            suplemLogico: bloqueaResponse.data.suplemLogico,
            noSolicitud: bloqueaResponse.data?.nsolicitud,
          });
          setShowButtons({
            ...showButtons,
            highRisk: true,
            guardarEndoso: true,
          });
        } else if (data.tipend === 18) {
          const body = {
            oficina: data.oficina,
            ramo: data.ramo,
            poliza: data.poliza,
          };
          const res = await endoso.configModDescripcion(body);
          if (res.data?.codigo === "1") {
            setFormDta({
              nivel: 1,
              noSolicitud: bloqueaResponse.data.nsolicitud,
              tipoEndoso: data.tipend,
            });
            setAutos(res.data.autos);
          }
        } else if (data.tipend === 16) {
          const data = await endoso.configType16(
            bloqueaResponse.data.nsolicitud
          );
          if (data?.data.codigo === 1) {
            setFormDta({
              nivel: 1,
              noSolicitud: bloqueaResponse.data.nsolicitud,
            });
            setShowButtons({ ...showButtons, guardarEndoso: true });
            setInitialData(data.data);
          } else {
            createMessage({
              text: data?.data?.mensaje ?? "Ocurrio un error con su solicitud",
              variant: "warning",
            });
          }
        } else if (data.tipend === 15) {
          const data = await endoso.nivel902({
            solicitud: bloqueaResponse.data.nsolicitud,
            nivel: bloqueaResponse.data.nivel,
            pagina: 1,
            clave: null,
          });
          if (data?.data.codigo === 1) {
            setBajaRiesgo15(data.data);
            setShowButtons({ ...showButtons, guardarEndoso: true });
            setFormDta({
              ...formDta,
              nivel: bloqueaResponse.data.nivel,
              suplemLogico: bloqueaResponse.data.suplemLogico,
              noSolicitud: bloqueaResponse.data?.nsolicitud,
              tipoEndoso: 15,
              cantidad: data.data.cantidad,
            });
          } else {
            createMessage({
              text: data?.data?.mensaje ?? "Ocurrio un error con su solicitud",
              variant: "warning",
            });
          }
        } else if (data.tipend === 14) {
          const dataBody = {
            oficina: data.oficina,
            poliza: data.poliza,
            ramo: data.ramo,
          };
          const dataResponse = await endoso.validaRegla902(dataBody);
          setShowMonthlySalary(dataResponse.data.sumaAseguradaFija);
          const config = await endoso.configType14(
            bloqueaResponse.data.nsolicitud
          );
          setInitialData(config?.data);
          setFormDta({
            nivel: bloqueaResponse.data.nivel,
            suplemLogico: bloqueaResponse.data.suplemLogico,
            noSolicitud: bloqueaResponse.data?.nsolicitud,
          });
          setShowButtons({
            ...showButtons,
            highRisk: true,
            guardarEndoso: true,
          });
        } else if (data.tipend === 20) {
          const res = await endoso.configType20(
            bloqueaResponse.data?.nsolicitud
          );
          if (res?.data?.codigo === "1") {
            setCargaB(res.data);
            setFormDta({
              nivel: bloqueaResponse.data.nivel,
              suplemLogico: bloqueaResponse.data.suplemLogico,
              noSolicitud: bloqueaResponse.data?.nsolicitud,
            });
            setShowButtons({
              ...showButtons,
              guardarEndoso: true,
              formulario: true,
            });
          } else
            createMessage({
              text: res?.data?.mensaje ?? "Ocurrio un error con su solicitud",
              variant: "warning",
            });
        } else if (data.tipend === 21) {
          const res = await endoso.secctions21(
            bloqueaResponse.data?.nsolicitud
          );
          if (res?.data?.codigo === 1) {
            setSections21(res?.data?.secciones);
          }
          setFormDta({
            nivel: 1,
            suplemLogico: bloqueaResponse.data.suplemLogico,
            noSolicitud: bloqueaResponse.data?.nsolicitud,
          });
          setShowButtons({
            ...showButtons,
            guardarEndoso: true,
          });
        } else if (data.tipend === 22) {
          setFormDta({
            nivel: 1,
            suplemLogico: bloqueaResponse.data.suplemLogico,
            noSolicitud: bloqueaResponse.data?.nsolicitud,
          });
          setShowButtons({
            ...showButtons,
            guardarEndoso: true,
          });
        } else if (data.tipend === 23) {
          setFormDta({
            nivel: 1,
            suplemLogico: bloqueaResponse.data.suplemLogico,
            noSolicitud: bloqueaResponse.data?.nsolicitud,
          });
          setShowButtons({
            ...showButtons,
            guardarEndoso: true,
          });
        } else {
          /* configuration */
          if (bloqueaResponse.data.nivel === 1) {
            const body = {
              solicitud: bloqueaResponse.data.nsolicitud,
              nivel: bloqueaResponse.data.nivel,
              llave: null,
            };
            const configResponse = await endoso.configuration(body);
            if (
              configResponse.data?.codigo === 1 ||
              configResponse.data?.mensaje ===
              "No hay una persona con el rol BP"
            ) {
              const items = { ...configResponse.data };
              items.nivel = bloqueaResponse.data.nivel;
              items.suplemLogico = bloqueaResponse.data.suplemLogico;
              items.noSolicitud = bloqueaResponse.data?.nsolicitud;
              setFormDta(items || {});
              setShowButtons({
                ...showButtons,
                formulario: true,
                formTable: false,
                guardarEndoso: true,
              });
            } else {
              setMessage({
                text:
                  configResponse.data?.mensaje ||
                  "Ocurrio un error con su solicitud",
                severity: "warning",
              });
              setFormDta({
                nivel: bloqueaResponse?.data?.nivel,
                noSolicitud: bloqueaResponse.data?.nsolicitud,
              });
              setShowMessage(true);
            }
          } else {
            /* levels */
            const body = {
              solicitud: bloqueaResponse.data.nsolicitud,
              nivel:
                bloqueaResponse.data.nivel === 3
                  ? 2
                  : bloqueaResponse.data.nivel,
              clave: null,
            };
            const levelResponse = await endoso.getLevel(body);
            if (levelResponse.data?.codigo === 1) {
              setRowsTable(levelResponse.data);
              setFormDta(
                {
                  nivel: bloqueaResponse.data?.nivel,
                  noSolicitud: bloqueaResponse.data?.nsolicitud,
                  suplemLogico: bloqueaResponse.data.suplemLogico,
                  niveles: levelResponse.data?.niveles,
                } || {}
              );
              if (data.tipend === 17) {
                setShowButtons({
                  ...showButtons,
                  formulario: false,
                  formTable: true,
                  guardarEndoso: true,
                  multiTableBaja: true,
                });
              } else if (data.tipend === 13 || data.tipend === 15) {
                setShowButtons({
                  ...showButtons,
                  formulario: false,
                  formTable: true,
                  check: true,
                  guardarEndoso: true,
                });
              } else {
                setShowButtons({
                  ...showButtons,
                  formulario: false,
                  formTable: true,
                  guardarEndoso: false,
                });
              }
            } else {
              setMessage({
                text:
                  bloqueaResponse.data?.mensaje ||
                  "Ocurrio un error con su solicitud",
                severity: "warning",
              });
              setShowMessage(true);
            }
          }
        }
      } else {
        setTimeout(() => {
          setMessage({
            text:
              bloqueaResponse.data?.mensaje?.length < 70
                ? bloqueaResponse.data.mensaje
                : "La póliza esta bloqueada",
            severity: "warning",
          });

          setShowMessage(true);
        }, 500);
      }
      setSpinner(false);
    };
    if (data.fechainicial === undefined && data.tipend) {
      setErrorsSelect({
        ...errorsSelect,
        date: "DEBE SELECCIONAR AL MENOS UNA FECHA",
      });
    } else if (data.fechainicial && data.tipend && !state.configuration) {
      setErrorsSelect({});
      const dateIntial = format(data.fechainicial, "dd/MM/yyyy");
      if (
          new Date(formatDate(state.finVigencia)).getTime() -
          new Date(formatDate(dateIntial)) <
          0 ||
          new Date(formatDate(state.iniVigencia)).getTime() -
          new Date(formatDate(dateIntial)) >
          0
      ) {
        setMessage({
          text: "Fecha fuera de vigencia",
          severity: "warning",
        });
        setShowMessage(true);
      } else {
        setMessage({
          text: "FECHA CORRECTA, FECHA ACTUAL SELECCIONADA",
          severity: "success",
        });
        setShowMessage(true);
        getBloquea();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (state?.mensajeBloqueo) {
      setMessage({
        text: state.mensajeBloqueo,
        severity: "warning",
      });
      setShowMessage(true);
    }
    const getTypesEndoso = async () => {
      const typesResponse = await endoso.listType(state.ramo);
      setType(typesResponse.data?.endosos || []);
      if (state.configuration) {
        /* set type of endoso */
        if (data.tipend === 18) {
          setAutos(state.configuration.initialData);
          setFormDta(state.configuration);
        } else if (state.configuration.nivel === 1) {
          setFormDta(state.configuration);
          if (data.tipend === 12) {
            setShowButtons({
              ...showButtons,
              highRisk: true,
              guardarEndoso: true,
            });
          } else if (data.tipend === 16) {
            setFormDta(state.configuration);
            setShowButtons({ ...showButtons, guardarEndoso: true });
            setInitialData(state?.configuration?.initialData);
          } else if (data.tipend === 14) {
            setInitialData(state?.configuration?.initialData);
            setFormDta(state.configuration);
            setShowButtons({
              ...showButtons,
              highRisk: true,
              guardarEndoso: true,
            });
          } else if (data.tipend === 20) {
            setFormDta(state.configuration);
            setCargaB(state.configuration.initialData);
            setShowButtons({
              ...showButtons,
              guardarEndoso: true,
              formulario: true,
            });
          } else if (data.tipend === 21) {
            setFormDta(state.configuration);
            setShowButtons({
              ...showButtons,
              guardarEndoso: true,
            })
          } else if (data.tipend === 22) {
            setFormDta(state.configuration);
            setShowButtons({
              ...showButtons,
              guardarEndoso: true,
            })
          } else if (data.tipend === 23) {
            setFormDta(state.configuration);
            setShowButtons({
              ...showButtons,
              guardarEndoso: true,
            })
          } else {
            if (state.configuration?.codigo === 1) {
              setShowButtons({
                ...showButtons,
                formulario: true,
                formTable: false,
                guardarEndoso: true,
              });
            } else {
              setMessage({
                text:
                  state.configuration?.mensaje ||
                  "Ocurrio un error con su solicitud",
                severity: "warning",
              });
              setShowMessage(true);
              if (data.tipend === 6) {
                setShowButtons({
                  ...showButtons,
                  formulario: true,
                  formTable: false,
                  guardarEndoso: true,
                });
              }
            }
          }
        } else {
          setRowsTable(state.configuration);
          setFormDta(state.configuration);
          if (data.tipend === 17) {
            setShowButtons({
              ...showButtons,
              formulario: false,
              formTable: true,
              guardarEndoso: true,
              multiTableBaja: true,
            });
          } else if (data.tipend === 15) {
            setFormDta({ ...state.configuration, cantidad: state.cantidad });
            setShowButtons({ ...showButtons, guardarEndoso: true });
            setBajaRiesgo15(state?.configuration?.initialData);
          } else if (data.tipend === 13 || data.tipend === 15) {
            setShowButtons({
              ...showButtons,
              formulario: false,
              formTable: true,
              check: true,
              guardarEndoso: true,
            });
          } else {
            setShowButtons({
              ...showButtons,
              formulario: false,
              formTable: true,
              guardarEndoso: false,
            });
          }
        }
        setDisableSelects(true);
      }
      setLoading(false);
    };
    if (state === undefined) history.push("/");
    else {
      getTypesEndoso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return <Spinner />;
  } else
    return (
      <Fragment>
        <SnackBar
          HandleShow={setShowMessage}
          show={showMessage}
          severity={message.severity}
          text={message.text}
          time={message.time ?? 2000}
          action={message.action}
        />
        <Fade in={!loading} timeout={600}>
          <div className={classes.root}>
            <div className={classes.sections}>
              <DataSection dataPoliza={state} dataContractor={dataContractor} />
            </div>

            <div className={classes.sections}>
              <div className={classes.subSections}>
                <Typography variant="h5" className={classes.title}>
                  <strong>Endoso</strong>
                </Typography>
              </div>
              <div className={classes.subSections}>
                <SelectSection
                  options={types}
                  change={handleChangeData}
                  data={data}
                  disableSelects={disableSelects}
                  error={errorsSelect}
                />
              </div>

              <div id="forms" className={classes.subSections}>
                {formDta.statusTable !== "CANCELADA" &&
                  formDta.statusTable !== "CONFIRMADA" &&
                  state?.status !== "Cancelado" && (
                    <FormsSection
                      loading={loading}
                      formDta={formDta}
                      rows={rowsTable}
                      setRows={setRowsTable}
                      setFormDta={setFormDta}
                      spinner={spinner}
                      setSpinner={setSpinner}
                      setMessage={setMessage}
                      setShowMessage={setShowMessage}
                      dataSend={dataSend}
                      setData={setDataSend}
                      setOldDat={setOldDat}
                      showButtons={showButtons}
                      infoTable={infoTable}
                      data={data}
                      setShowButtons={setShowButtons}
                      errorsSelect={errorsSelect}
                    />
                  )}
                {formDta.statusTable === "CONFIRMADA" && (
                  <FormTable rows={documents} type={2} actionHeader />
                )}
              </div>
              <div className={classes.subSections}>
                {formDta.statusTable !== "CANCELADA" &&
                  formDta.statusTable !== "CONFIRMADA" &&
                  state?.status !== "Cancelado" && (
                    <FooterSection
                      setMessage={setMessage}
                      setShowMessage={setShowMessage}
                      setSpinner={setSpinner}
                      submit={handleSubmitData}
                      showButtons={showButtons}
                      spinner={spinner}
                      formDta={formDta}
                      setShowButtons={setShowButtons}
                      data={data}
                      dataSend={dataSend}
                      setDataContractor={setDataContractor}
                    />
                  )}
              </div>
            </div>
          </div>
        </Fade>
      </Fragment>
    );
};

export default Endosos;
